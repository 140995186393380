import { IEnvironmentTNK } from "@sequor/security";
import jwt from 'jsonwebtoken';

class WebEnvironmentTNK implements IEnvironmentTNK {
    private getTokenKey = () => process.env.REACT_APP_TOKEN_KEY

    async to(payload: any, expiresIn: number):Promise<string> {
        expiresIn = Math.round(expiresIn / 1000)
        return jwt.sign(payload, this.getTokenKey(), { algorithm: 'HS256', expiresIn })
    }

    async from(token: string):Promise<any> {
        return jwt.verify(token, this.getTokenKey(), { algorithms: ['HS256'], ignoreExpiration: true, ignoreNotBefore: true });
    }
}

export default new WebEnvironmentTNK()